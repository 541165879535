import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["for", "invalid", "disabled"]
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = ["invalid", "disabled", "aria-controls", "aria-expanded", "aria-activedescendant", "aria-label"]
const _hoisted_5 = {
  key: 0,
  class: "BsPhoneNumberDropdown__combobox__flag"
}
const _hoisted_6 = ["id", "placeholder", "aria-describedby", "value", "required", "disabled"]
const _hoisted_7 = ["id"]
const _hoisted_8 = ["id"]
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountryFlag = _resolveComponent("CountryFlag")!
  const _component_BsPhoneNumberDropdownItem = _resolveComponent("BsPhoneNumberDropdownItem")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    ref: "container",
    class: "BsPhoneNumberDropdown form-control-helper"
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.inputId,
          class: "BsPhoneNumberDropdown__label form-label",
          invalid: _ctx.dirty && _ctx.invalid,
          disabled: _ctx.disabled
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        ref: "button",
        role: "combobox",
        class: "BsPhoneNumberDropdown__combobox btn btn-outline-secondary",
        type: "button",
        invalid: _ctx.dirty && _ctx.invalid,
        disabled: _ctx.disabled,
        "aria-haspopup": "listbox",
        "aria-controls": _ctx.dropdownId,
        "aria-expanded": _ctx.opened,
        "aria-activedescendant": _ctx.focusedId,
        "aria-label": _ctx.locales.select_country_code,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleDropdownButtonClick && _ctx.handleDropdownButtonClick(...args))),
        onKeydown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleDropdownButtonKeyDown && _ctx.handleDropdownButtonKeyDown(...args)))
      }, [
        (_ctx.selectedCountry)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_CountryFlag, {
                country: _ctx.selectedCountry?.code,
                size: "normal"
              }, null, 8, ["country"])
            ]))
          : _createCommentVNode("", true),
        _cache[4] || (_cache[4] = _createElementVNode("i", { class: "bi bi-caret-down-fill" }, null, -1))
      ], 40, _hoisted_4),
      _createElementVNode("input", {
        id: _ctx.inputId,
        ref: "input",
        class: _normalizeClass(["BsPhoneNumberDropdown__input form-control", {
          'is-invalid': _ctx.dirty && _ctx.invalid
        }]),
        placeholder: _ctx.locales.phone_number_placeholder,
        "aria-describedby": _ctx.invalidMessage && _ctx.invalidMessageId,
        value: _ctx.modelValue.number,
        required: _ctx.showRequiredAttr,
        disabled: _ctx.disabled,
        onInput: _cache[2] || (_cache[2] = (event) => _ctx.handleInput(event as InputEvent)),
        onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
      }, null, 42, _hoisted_6),
      (_ctx.dirty)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            id: _ctx.invalidMessageId,
            class: "invalid-feedback"
          }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_7))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("ul", {
      id: _ctx.dropdownId,
      ref: "dropdown",
      class: _normalizeClass(["BsPhoneNumberDropdown__dropdown dropdown-menu", { show: _ctx.opened }]),
      role: "listbox",
      style: _normalizeStyle({
        maxWidth: _ctx.dropdownMaxWidth + 'px',
        maxHeight: _ctx.dropdownMaxHeight + 'px',
        top: _ctx.dropdownTopOffset + 'px'
      }),
      tabindex: "-1"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frequentCountries, (country) => {
        return (_openBlock(), _createBlock(_component_BsPhoneNumberDropdownItem, {
          id: _ctx.createFocusedId(country.code),
          key: country.code,
          focused: country.code === _ctx.focusedCountry?.code,
          selected: country.code === _ctx.selectedCountry?.code,
          country: country,
          onClick: ($event: any) => (_ctx.handleDropdownItemClick(country))
        }, null, 8, ["id", "focused", "selected", "country", "onClick"]))
      }), 128)),
      (_ctx.frequentCountries.length)
        ? (_openBlock(), _createElementBlock("li", _hoisted_9, _cache[5] || (_cache[5] = [
            _createElementVNode("hr", { class: "dropdown-divider" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.unFrequentCountries, (country) => {
        return (_openBlock(), _createBlock(_component_BsPhoneNumberDropdownItem, {
          id: _ctx.createFocusedId(country.code),
          key: country.code,
          focused: country.code === _ctx.focusedCountry?.code,
          selected: country.code === _ctx.selectedCountry?.code,
          country: country,
          onClick: ($event: any) => (_ctx.handleDropdownItemClick(country))
        }, null, 8, ["id", "focused", "selected", "country", "onClick"]))
      }), 128))
    ], 14, _hoisted_8)
  ], 8, _hoisted_1))
}