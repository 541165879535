
import { defineComponent, onMounted, ref } from 'vue'
import locales from './LoadingMessage.locales.en.json'
import { getMotivationalQuote } from '@/api/UserApi'

export default defineComponent({
  name: 'LoadingMessage',
  setup() {
    interface InspirationalQuote {
      quote: string
      author: string
    }

    const inspirationalQuote = ref<InspirationalQuote>({
      quote: '',
      author: ''
    })

    onMounted(async () => {
      try {
        const res = await getMotivationalQuote()
        inspirationalQuote.value = res.data
      } catch (err) {
        console.error(err)
        inspirationalQuote.value = { quote: locales.quote, author: locales.author }
      }
    })

    return {
      locales,
      inspirationalQuote
    }
  }
})
