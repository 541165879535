import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "registration-heading" }
const _hoisted_2 = { class: "register-subtext" }
const _hoisted_3 = { class: "email-input-segment" }
const _hoisted_4 = { class: "w-100 text-center" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BsTextField = _resolveComponent("BsTextField")!

  return (_openBlock(), _createElementBlock("form", {
    class: "register-form-segment",
    "aria-labelledby": "registration-heading",
    novalidate: "",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.verifyEmail && _ctx.verifyEmail(...args)), ["prevent"]))
  }, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.locales.title), 1),
    _createElementVNode("p", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.locales.subtitle) + " ", 1),
      _createVNode(_component_router_link, { to: _ctx.loginRoute }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.locales.here), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BsTextField, {
        id: "Register__email_address",
        modelValue: _ctx.formFields.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formFields.email) = $event)),
        class: "mb-3",
        type: "email",
        label: _ctx.locales.email_address,
        placeholder: _ctx.locales.email_address.toLowerCase(),
        validation: _ctx.v$.email,
        disabled: _ctx.disabled,
        "auto-trim": ""
      }, null, 8, ["modelValue", "label", "placeholder", "validation", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        type: "submit",
        disabled: _ctx.disabled
      }, _toDisplayString(_ctx.locales.verify), 9, _hoisted_5)
    ])
  ], 32))
}