
import {defineComponent, ref, computed, onMounted, reactive, watch} from 'vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsPhoneNumberDropdown from '@/components/BsPhoneNumberDropdown/BsPhoneNumberDropdown.vue'
import locales from './EditProfileDialog.locales.en.json'
import { createAutoIncrementId } from '@/utils/VueTools'
import { injectCountryService } from '@/providers/CountryService/CountryService.utils'
import { ICountry } from '@/providers/CountryService/CountryService.interfaces'
import { EditProfileViewModel, EditProfileValidationArgs } from './EditProfileDialog.interfaces'
import useVuelidate from '@vuelidate/core'
import { email, helpers, required } from '@vuelidate/validators'
import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

const isPhoneNumber = helpers.withMessage(
  locales.invalid_phone_number,
  (phoneNumber: {number: string, dialingCode: string, countryCode: string }) =>
    !!phoneNumber.dialingCode
    && (phoneUtil.isValidNumberForRegion(phoneUtil.parse(phoneNumber.number, phoneNumber.countryCode), phoneNumber.countryCode) ?? false)
)

const noPlusSign = helpers.withMessage(
  locales.invalid_email_plus,
  (value: string) => !(value.includes('+'))
)

export default defineComponent({
  components: { BsTextField, BsSelect, BsPhoneNumberDropdown },
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('EditProfileDialog')
    },
    disabled: Boolean
  },
  emits: ['submit-form'],
  setup(props, { emit }) {
    const countryService = injectCountryService()

    const opened = ref(false)
    const countries = ref<ICountry[]>([])
    const formHasChanges = ref(false)
    const initialValues = ref<EditProfileViewModel | null>(null)
    
    const titleId = computed<string>(() => {
      return props.id + '__title'
    })
    const formId = computed<string>(() => {
      return props.id + '__form'
    })
    const formFields = reactive<EditProfileViewModel>({
      email: '',
      firstName: '',
      lastName: '',
      country: '',
      phoneNumber: {
        number: '',
        dialingCode: '',
        countryCode: ''
      }
    })
    const rules: EditProfileValidationArgs = {
      email: { required, email, noPlusSign },
      firstName: { required },
      lastName: { required },
      country: { required },
      phoneNumber: { required, isPhoneNumber }
    }

    const v$ = useVuelidate(rules, formFields)

    // Check for form changes to show notification
    watch(
      [
        () => formFields.email,
        () => formFields.firstName,
        () => formFields.lastName,
        () => formFields.phoneNumber.number,
        () => formFields.phoneNumber.dialingCode,
        () => formFields.country
      ],
      () => {
        if (!initialValues.value) return
        
        formHasChanges.value = 
          formFields.email !== initialValues.value.email ||
          formFields.firstName !== initialValues.value.firstName ||
          formFields.lastName !== initialValues.value.lastName ||
          formFields.phoneNumber.number !== initialValues.value.phoneNumber.number ||
          formFields.phoneNumber.dialingCode !== initialValues.value.phoneNumber.dialingCode ||
          formFields.country !== initialValues.value.country
      }
    )
    
    onMounted(async () => {
      countries.value = (await countryService?.getCountries()) ?? []
    })

    function open(model?: EditProfileViewModel) {
      opened.value = true
      formHasChanges.value = false
      
      if (model) {
        // Store initial values for change detection
        initialValues.value = { ...model, phoneNumber: { ...model.phoneNumber } }
        
        // Set form values
        formFields.email = model.email
        formFields.firstName = model.firstName
        formFields.lastName = model.lastName
        formFields.phoneNumber = { ...model.phoneNumber }
        formFields.country = model.country
        
        // Reset validation state
        v$.value.$reset()
      }
    }

    function close() {
      opened.value = false
    }
    
    function handleDialogHide() {
      if (formHasChanges.value && v$.value.$anyDirty) {
        if (confirm('You have unsaved changes. Are you sure you want to close this dialog?')) {
          resetForm()
        } else {
          opened.value = true // Reopen the dialog
          return
        }
      }
      resetForm()
    }
    
    function resetForm() {
      v$.value.$reset()
      formHasChanges.value = false
      initialValues.value = null
    }

    function handleSubmit() {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      emit('submit-form', formFields)
    }

    function handleCancel() {
      if (formHasChanges.value && v$.value.$anyDirty) {
        if (confirm('You have unsaved changes. Are you sure you want to cancel?')) {
          opened.value = false
          resetForm()
        }
      } else {
        opened.value = false
        resetForm()
      }
    }

    return {
      locales,
      opened,
      titleId,
      formId,
      countries,
      formFields,
      formHasChanges,
      v$,
      open,
      close,
      handleDialogHide,
      handleSubmit,
      handleCancel
    }
  }
})
