import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "text-center position-sticky top-0" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "top-header-content" }
const _hoisted_4 = { class: "logo-segment" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "main-body-content container" }
const _hoisted_8 = { class: "content-segment" }
const _hoisted_9 = { class: "footer-content" }
const _hoisted_10 = {
  key: 0,
  class: "need-help-segment"
}
const _hoisted_11 = { class: "text-center mt-1.5" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  href: "https://www.barbri.com/contact/",
  class: "need-help-btn"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["template-main-segment", _ctx.moveBannerImgRightSide ? 'toggle-template' : ''])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(['left-content d-none d-lg-block splash-screen-backdrop',
               _ctx.siteSkin,
               { 'slide-left': _ctx.slideLeft, 'slide-right': _ctx.slideRight}])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: require('~/img/splash-backdrop.svg?asset'),
          alt: _ctx.locales.splash_backdrop
        }, null, 8, _hoisted_2)
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(['right-content', _ctx.siteSkin + '-padding', {'slide-up': _ctx.slideUp }])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "header", {}, () => [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.useSkinLogo)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: _normalizeClass([_ctx.siteSkin + '-logo-img']),
                  src: require('~/img/' + _ctx.siteSkin + '-logo.png'),
                  alt: 'locales.' + _ctx.siteSkin + '_logo'
                }, null, 10, _hoisted_5))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  class: "barbri-logo-img",
                  src: require('~/img/barbri-logo.png'),
                  alt: _ctx.locales.barbri_logo
                }, null, 8, _hoisted_6))
          ])
        ], true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "content", {}, () => [
            _cache[2] || (_cache[2] = _createElementVNode("p", null, "main content", -1))
          ], true)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _renderSlot(_ctx.$slots, "footer", {}, () => [
          (_ctx.displayLinks)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  (_ctx.siteSkin !== _ctx.Skin.powerscore)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("button", {
                          class: "btn btn-link mb-1",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleForgotUn && _ctx.handleForgotUn(...args)))
                        }, _toDisplayString(_ctx.locales.link_forgot_username), 1)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", null, [
                    _createElementVNode("button", {
                      class: "btn btn-link mb-1",
                      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleForgotPw && _ctx.handleForgotPw(...args)))
                    }, _toDisplayString(_ctx.locales.link_forgot_password), 1)
                  ])
                ]),
                _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.locales.need_help_question), 1)
              ]))
            : _createCommentVNode("", true)
        ], true)
      ])
    ], 2)
  ], 2))
}