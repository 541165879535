
import { computed, PropType, ref, unref } from 'vue'
import { Configuration } from '@/config/config'
import type { Validation } from '@vuelidate/core'
import VueRecaptcha from 'vue3-recaptcha2'

export default {
  components: {
    VueRecaptcha
  },
  props: {
     id: {
      type: String,
      default: 'RecaptchaComponent'
    },
    modelValue: {
      type: String,
      default: ''
    },
    validation: {
      type: Object as PropType<Validation>,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  setup(props: any, context: any) {
    const invalidMessageId = computed(() => props.id + '__invalid_message')
    const vueRecaptcha = ref<typeof VueRecaptcha | null>(null)

    const recaptchaSiteKey = computed(() => {
      return Configuration.RECAPTCHA_SITE_KEY
    })

    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })

    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })

    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })

    const recaptchaVerified = (response: string) => {
      context.emit('update:modelValue', response)
    }

    const recaptchaExpired = () => {
      context.emit('update:modelValue', '')
      vueRecaptcha.value?.reset()
    }

    return {
      recaptchaSiteKey,
      invalidMessageId,
      dirty,
      invalid,
      invalidMessage,
      recaptchaVerified,
      recaptchaExpired,
      vueRecaptcha
    }
  }
}
