
import { defineComponent, PropType } from 'vue'
import CountryFlag from 'vue-country-flag-next'
import { ICountry } from '@/providers/CountryService/CountryService'

export const BsPhoneNumberDropdownItem = defineComponent({
  name: 'BsPhoneNumberDropdownItem',
  components: { CountryFlag },
  props: {
    country: {
      type: Object as PropType<ICountry>,
      default: () => ({ code: '', dialingCode: '', name: '' })
    },
    focused: Boolean,
    selected: Boolean
  }
})

export default BsPhoneNumberDropdownItem
