
import { defineComponent, watch } from 'vue'
import SuccessTick from '~/icons/ToastSuccessTick.svg'
import ExclamationCircle from '~/icons/ExclamationCirlce.svg'

export const FooterSection = defineComponent({
  name: 'FooterSection',
  components: {
    SuccessTick,
    ExclamationCircle
  },
  props: {
    position: {
      type: String,
      default: () => 'top-center'
    },
    type: {
      type: String,
      default: () => 'success'
    },
    isOpen: {
      type: Boolean
    },
    title: {
      type: String,
      default: () => ''
    },
    subText: {
      type: String,
      default: () => ''
    },
    duration: {
      type: Number,
      default: 5000
    }
  },
  emits: ['update:isOpen'],
  setup(props, { emit }) {
    let removeTimeout: ReturnType<typeof setTimeout>

    watch(
      () => props.isOpen,
      (newState) => {
        if (newState) {
          autoCloseToast()
        }
      }
    )

    function autoCloseToast() {
      removeTimeout = setTimeout(() => {
        emit('update:isOpen', false)
        clearTimeout(removeTimeout)
      }, props.duration)
    }
  }
})

export default FooterSection
