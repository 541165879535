import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "justify-content-center" }
const _hoisted_3 = { class: "justify-content-center" }
const _hoisted_4 = { class: "g-3 mt-2 justify-content-center" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "text-center mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!

  return (_openBlock(), _createElementBlock("form", {
    class: "LoginForm",
    novalidate: "",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(["LoginForm__heading text-center", { [_ctx.siteSkin + '-text']: true }])
    }, " Welcome Back! ", 2),
    (_ctx.siteSkin === _ctx.Skin.powerscore)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[4] || (_cache[4] = [
          _createElementVNode("p", null, "Welcome to PowerScore! We've recently enhanced our login process to better serve you. If you're a returning user and can't log in, click 'Forgot Password'. If you don't receive an email, select 'Register' using the same email to create a new login.", -1),
          _createElementVNode("p", null, [
            _createTextVNode("Need assistance? Contact our Customer Service Team at "),
            _createElementVNode("strong", null, "800-545-1750"),
            _createTextVNode(" or "),
            _createElementVNode("a", { href: "mailto:contact@powerscore.com" }, "contact@powerscore.com"),
            _createTextVNode(", available 7am-7pm CT.")
          ], -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BsTextField, {
        id: "username",
        modelValue: _ctx.formFields.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formFields.email) = $event)),
        class: "mb-3",
        type: "text",
        label: _ctx.locales.username,
        placeholder: _ctx.locales.username,
        disabled: _ctx.disabled
      }, null, 8, ["modelValue", "label", "placeholder", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BsTextField, {
        id: "login-password",
        modelValue: _ctx.formFields.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formFields.password) = $event)),
        class: "mb-3",
        type: "password",
        label: _ctx.locales.password,
        placeholder: _ctx.locales.password,
        validation: _ctx.v$.password,
        disabled: _ctx.disabled,
        "auto-trim": "",
        "toggle-password": ""
      }, null, 8, ["modelValue", "label", "placeholder", "validation", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        class: "LoginForm__btn btn btn-primary",
        type: "submit",
        disabled: _ctx.disabled
      }, _toDisplayString(_ctx.locales.btn_login), 9, _hoisted_5)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (!_ctx.hideRegister)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-link",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleRegister && _ctx.handleRegister(...args)))
          }, _toDisplayString(_ctx.locales.btn_register), 1))
        : _createCommentVNode("", true)
    ])
  ], 32))
}