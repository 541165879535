import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_barbri_global_nav = _resolveComponent("barbri-global-nav")!

  return (_openBlock(), _createBlock(_component_barbri_global_nav, {
    "data-sign-in-url": $setup.signInPath,
    "data-log-out-url": $setup.logOutPath,
    "data-first-name": $setup.firstName,
    "data-last-name": $setup.lastName
  }, null, 8, ["data-sign-in-url", "data-log-out-url", "data-first-name", "data-last-name"]))
}