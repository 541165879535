
import { defineComponent, reactive, PropType } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import locales from './RegisterEmailForm.locales.en.json'
import { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  name: 'RegisterEmailForm',
  components: {
    BsTextField
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loginRoute: {
      type: [String, Object] as PropType<RouteLocationRaw>,
      default: '/'
    }
  },
  emits: ['submit', 'login'],
  setup(props, { emit }) {
    const formFields = reactive({
      email: ''
    })

    const noPlusSign = helpers.withMessage(
      locales.invalid_email_plus,
      (value: string) => !(value.includes('+'))
    )

    const rules = {
      email: { required, email, noPlusSign }
    }

    const v$ = useVuelidate(rules, formFields)

    function verifyEmail() {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }
      emit('submit', formFields.email)
    }

    return {
      v$,
      formFields,
      verifyEmail,
      locales
    }
  }
})
