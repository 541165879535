import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-motivation-msg-segment" }
const _hoisted_2 = { class: "content-segment" }
const _hoisted_3 = { class: "quote-item" }
const _hoisted_4 = { class: "author-name" }
const _hoisted_5 = { class: "footer-logo-segment" }
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.inspirationalQuote.quote), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.inspirationalQuote.author), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("img", {
        src: require('~/img/barbri-logo.png'),
        alt: _ctx.locales.global_logo,
        class: "barbri-logo"
      }, null, 8, _hoisted_6)
    ])
  ]))
}