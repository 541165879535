import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "text-muted mb-4" }
const _hoisted_4 = ["id", "aria-labelledby"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "row g-3" }
const _hoisted_7 = { class: "col-lg-6" }
const _hoisted_8 = { class: "col-lg-6" }
const _hoisted_9 = { class: "my-3" }
const _hoisted_10 = { class: "row g-3" }
const _hoisted_11 = { class: "col-lg-6" }
const _hoisted_12 = { class: "col-lg-6" }
const _hoisted_13 = {
  selected: "",
  disabled: "",
  value: ""
}
const _hoisted_14 = ["value"]
const _hoisted_15 = {
  key: 0,
  class: "alert alert-info mt-4"
}
const _hoisted_16 = { class: "d-flex justify-content-between w-100" }
const _hoisted_17 = ["disabled"]
const _hoisted_18 = ["form", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BsTextField = _resolveComponent("BsTextField")!
  const _component_BsPhoneNumberDropdown = _resolveComponent("BsPhoneNumberDropdown")!
  const _component_BsSelect = _resolveComponent("BsSelect")!
  const _component_prime_dialog = _resolveComponent("prime-dialog")!

  return (_openBlock(), _createBlock(_component_prime_dialog, {
    visible: _ctx.opened,
    "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.opened) = $event)),
    class: "EditProfileDialog",
    modal: "",
    "close-on-escape": "",
    header: _ctx.locales.title,
    style: { maxWidth: '700px' }
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[8] || (_cache[8] = _createElementVNode("i", { class: "bi bi-person-gear fs-4 me-2" }, null, -1)),
        _createElementVNode("h1", {
          id: _ctx.titleId,
          class: "EditProfileDialog__title mb-0"
        }, _toDisplayString(_ctx.locales.title), 9, _hoisted_2)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.locales.subtitle || 'Update your personal information below'), 1),
      _createElementVNode("form", {
        id: _ctx.formId,
        novalidate: "",
        "aria-labelledby": _ctx.titleId,
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
      }, [
        _createElementVNode("fieldset", { disabled: _ctx.disabled }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_BsTextField, {
                modelValue: _ctx.formFields.firstName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formFields.firstName) = $event)),
                validation: _ctx.v$.firstName as any,
                label: _ctx.locales.first_name,
                placeholder: _ctx.locales.first_name,
                required: "",
                autocomplete: "given-name"
              }, {
                prepend: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createElementVNode("i", { class: "bi bi-person" }, null, -1)
                ])),
                _: 1
              }, 8, ["modelValue", "validation", "label", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_BsTextField, {
                modelValue: _ctx.formFields.lastName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formFields.lastName) = $event)),
                validation: _ctx.v$.lastName as any,
                label: _ctx.locales.last_name,
                placeholder: _ctx.locales.last_name,
                required: "",
                autocomplete: "family-name"
              }, {
                prepend: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createElementVNode("i", { class: "bi bi-person" }, null, -1)
                ])),
                _: 1
              }, 8, ["modelValue", "validation", "label", "placeholder"])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_BsTextField, {
              modelValue: _ctx.formFields.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formFields.email) = $event)),
              validation: _ctx.v$.email as any,
              label: _ctx.locales.email_address,
              placeholder: _ctx.locales.email_address,
              required: "",
              autocomplete: "email"
            }, {
              prepend: _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("i", { class: "bi bi-envelope" }, null, -1)
              ])),
              _: 1
            }, 8, ["modelValue", "validation", "label", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_BsPhoneNumberDropdown, {
                modelValue: _ctx.formFields.phoneNumber,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formFields.phoneNumber) = $event)),
                validation: _ctx.v$.phoneNumber as any,
                label: _ctx.locales.phone_number,
                placeholder: _ctx.locales.phone_number,
                required: "",
                autocomplete: "tel"
              }, null, 8, ["modelValue", "validation", "label", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_BsSelect, {
                modelValue: _ctx.formFields.country,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formFields.country) = $event)),
                validation: _ctx.v$.country as any,
                label: _ctx.locales.country,
                placeholder: _ctx.locales.country,
                required: "",
                autocomplete: "country"
              }, {
                prepend: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createElementVNode("i", { class: "bi bi-globe" }, null, -1)
                ])),
                default: _withCtx(() => [
                  _createElementVNode("option", _hoisted_13, _toDisplayString(_ctx.locales.select_a_country), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: country.code,
                      value: country.code
                    }, _toDisplayString(country.name), 9, _hoisted_14))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "validation", "label", "placeholder"])
            ])
          ])
        ], 8, _hoisted_5),
        (_ctx.formHasChanges)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, _cache[13] || (_cache[13] = [
              _createElementVNode("i", { class: "bi bi-info-circle me-2" }, null, -1),
              _createTextVNode(" Changes will be applied immediately upon saving. ")
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("button", {
            class: "btn btn-outline-primary",
            type: "button",
            disabled: _ctx.disabled,
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
          }, [
            _cache[14] || (_cache[14] = _createElementVNode("i", { class: "bi bi-x-circle me-1" }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.locales.cancel), 1)
          ], 8, _hoisted_17),
          _createElementVNode("button", {
            class: "btn btn-primary",
            type: "submit",
            form: _ctx.formId,
            disabled: _ctx.disabled || (_ctx.v$.$anyDirty && _ctx.v$.$invalid)
          }, [
            _cache[15] || (_cache[15] = _createElementVNode("i", { class: "bi bi-check-circle me-1" }, null, -1)),
            _createTextVNode(" " + _toDisplayString(_ctx.locales.save), 1)
          ], 8, _hoisted_18)
        ])
      ], 40, _hoisted_4)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}