import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container my-4" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex justify-content-center align-items-center vh-50",
  "aria-live": "polite"
}
const _hoisted_3 = {
  key: 1,
  class: "alert alert-danger",
  role: "alert"
}
const _hoisted_4 = {
  key: 2,
  class: "card shadow-sm mb-4"
}
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalNav = _resolveComponent("GlobalNav")!
  const _component_my_account_profile = _resolveComponent("my-account-profile")!
  const _component_EditProfileDialog = _resolveComponent("EditProfileDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_GlobalNav),
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "mb-4" }, " My Account ", -1)),
      ($setup.isLoading && !$setup.isReady)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
            _createElementVNode("div", { class: "d-flex flex-column align-items-center" }, [
              _createElementVNode("div", {
                class: "spinner-border text-primary mb-3",
                role: "status",
                "aria-busy": "true"
              }, [
                _createElementVNode("span", { class: "visually-hidden" }, "Loading user profile...")
              ]),
              _createElementVNode("p", null, "Loading your profile information...")
            ], -1)
          ])))
        : _createCommentVNode("", true),
      ($setup.loadError)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[2] || (_cache[2] = [
            _createElementVNode("i", { class: "bi bi-exclamation-triangle-fill me-2" }, null, -1),
            _createTextVNode(" Unable to load your profile information. Please try refreshing the page. ")
          ])))
        : _createCommentVNode("", true),
      (Object.keys($setup.oktaUser).length !== 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header bg-light" }, [
              _createElementVNode("h2", { class: "h5 mb-0" }, " Profile Information ")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_my_account_profile, { "okta-user": $setup.oktaUser }, {
                actions: _withCtx(() => [
                  _createElementVNode("button", {
                    class: "btn btn-primary",
                    disabled: $setup.isLoading || !$setup.isReady,
                    "aria-label": "Edit profile information",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.handleEditButtonClick && $setup.handleEditButtonClick(...args)))
                  }, _cache[3] || (_cache[3] = [
                    _createElementVNode("i", { class: "bi bi-pencil-fill me-2" }, null, -1),
                    _createTextVNode("Edit ")
                  ]), 8, _hoisted_6)
                ]),
                _: 1
              }, 8, ["okta-user"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_EditProfileDialog, {
      ref: "editProfileDialog",
      disabled: $setup.isLoading || !$setup.isReady,
      onSubmitForm: $setup.handleEditUserSubmit
    }, null, 8, ["disabled", "onSubmitForm"])
  ], 64))
}