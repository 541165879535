import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "verify-code-main",
  "aria-labelledby": "code-main-heading",
  novalidate: ""
}
const _hoisted_2 = {
  id: "code-main-heading",
  class: "verify-code-heading"
}
const _hoisted_3 = { class: "verify-code-subtext" }
const _hoisted_4 = { class: "verify-code-subtext" }
const _hoisted_5 = {
  ref: "codeInputWrapper",
  class: "input-wrapper-segment"
}
const _hoisted_6 = ["onUpdate:modelValue", "autofocus", "disabled", "aria-label", "onKeydown", "onInput"]
const _hoisted_7 = { class: "status-icon-segment" }
const _hoisted_8 = ["aria-label"]
const _hoisted_9 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidCodeIcon = _resolveComponent("ValidCodeIcon")!
  const _component_InvalidCodeIcon = _resolveComponent("InvalidCodeIcon")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.locales.title), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.locales.sent_code) + " " + _toDisplayString(_ctx.email), 1),
    _createElementVNode("p", _hoisted_4, [
      _createTextVNode(_toDisplayString(_ctx.locales.code_question) + " ", 1),
      _createElementVNode("button", {
        class: "resend-btn",
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleResendButtonClick && _ctx.handleResendButtonClick(...args)))
      }, _toDisplayString(_ctx.locales.code_click), 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valueParts, (char, index) => {
          return _withDirectives((_openBlock(), _createElementBlock("input", {
            key: index,
            "onUpdate:modelValue": ($event: any) => ((_ctx.valueParts[index]) = $event),
            autofocus: index === 0,
            type: "text",
            class: _normalizeClass({
            'valid-code': _ctx.isValidationComplete && _ctx.isCodeValid,
            'invalid-code':
              _ctx.isValidationComplete && !_ctx.isValidatingCode && !_ctx.isCodeValid,
            'input-box-element': true,
            ['input-box-element-' + index]: true
          }),
            maxlength: "1",
            disabled: _ctx.isValidationComplete,
            "aria-label": index + 1 + ' of ' + _ctx.valueParts.length,
            onKeydown: ($event: any) => (_ctx.handleKeyDown($event, index)),
            onInput: ($event: any) => (_ctx.handleInput(index)),
            onPaste: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPaste && _ctx.onPaste(...args)))
          }, null, 42, _hoisted_6)), [
            [_vModelText, _ctx.valueParts[index]]
          ])
        }), 128)),
        _createElementVNode("span", _hoisted_7, [
          (_ctx.isValidationComplete)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (!_ctx.isValidatingCode && _ctx.isCodeValid)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      "aria-label": _ctx.locales.valid_code
                    }, [
                      _createVNode(_component_ValidCodeIcon)
                    ], 8, _hoisted_8))
                  : (!_ctx.isValidatingCode && !_ctx.isCodeValid)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        "aria-label": _ctx.locales.invalid_code
                      }, [
                        _createVNode(_component_InvalidCodeIcon)
                      ], 8, _hoisted_9))
                    : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true)
        ])
      ], 512)
    ])
  ]))
}