import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["for", "invalid", "disabled"]
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = {
  key: 0,
  class: "input-group-text"
}
const _hoisted_5 = ["id", "aria-describedby", "required", "value", "disabled"]
const _hoisted_6 = {
  key: 1,
  class: "input-group-text"
}
const _hoisted_7 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "BsSelect form-control-helper"
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.selectId,
          class: "BsSelect__label form-label",
          invalid: _ctx.dirty && _ctx.invalid,
          disabled: _ctx.disabled
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.prepend)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.prepend), 1))
        : _createCommentVNode("", true),
      _createElementVNode("select", {
        id: _ctx.selectId,
        class: _normalizeClass(["BsSelect__select form-select", {
          'is-invalid': _ctx.dirty && _ctx.invalid
        }]),
        "aria-describedby": _ctx.invalidMessage && _ctx.invalidMessageId,
        required: _ctx.showRequiredAttr,
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 42, _hoisted_5),
      (_ctx.append)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.append), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      id: _ctx.invalidMessage && _ctx.invalidMessageId,
      class: "invalid-feedback"
    }, _toDisplayString(_ctx.invalidMessage), 9, _hoisted_7)
  ], 8, _hoisted_1))
}