
import { defineComponent, PropType, computed, unref } from 'vue'
import type { Validation } from '@vuelidate/core'
import { createAutoIncrementId } from '@/utils/VueTools'

export const BsCheckbox = defineComponent({
  name: 'BsCheckbox',
  props: {
    id: {
      type: String,
      default: createAutoIncrementId('BsCheckbox')
    },
    modelValue: Boolean,
    disabled: Boolean,
    validation: {
      type: Object as PropType<Validation>,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const inputId = computed(() => props.id + '__input')
    const invalidMessageId = computed(() => props.id + '__invalid_message')

    const dirty = computed<boolean>(() => {
      return props.validation?.$dirty ?? false
    })

    const invalid = computed<boolean>(() => {
      return props.validation?.$invalid ?? false
    })

    const invalidMessage = computed<string>(() => {
      const message = props.validation?.$errors?.[0]?.$message ?? ''
      return unref(message)
    })

    function handleChange(event: Event) {
      const target = event.target as HTMLInputElement
      emit('update:modelValue', target.checked)
    }

    return {
      inputId,
      invalidMessageId,
      dirty,
      invalid,
      invalidMessage,
      handleChange
    }
  }
})

export default BsCheckbox
