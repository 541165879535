
import { oktaAuth, UserClaimsExtended } from '@/okta'
import { ref, onMounted, onBeforeMount } from 'vue'
export default {
  setup() {
    const oktaUser = ref({} as UserClaimsExtended)
    const firstName = ref('') 
    const lastName = ref('')
    const signInPath = ref('/login')
    const logOutPath = ref('/logout')
    const externalScript1 = 'https://fd-barbri-api-global-dev.z01.azurefd.net/globalnav/js/main.js'
    const externalScript2 = 'https://fd-barbri-api-global-dev.z01.azurefd.net/globalnav/js/chunk-vendors.js'
    onMounted(async () => {
      oktaUser.value = await oktaAuth.getUser()
      firstName.value = oktaUser.value.firstName ?? ''
      lastName.value = oktaUser.value.lastName ?? ''
      const script1 = document.createElement('script')
      script1.src = externalScript1
      script1.onload = () => {}
      document.head.appendChild(script1)
      const script2 = document.createElement('script')
      script2.src = externalScript2
      script2.onload = () => {}
      document.head.appendChild(script2)
    })
    onBeforeMount(async () => {
      // Clean up by removing the script when the component is destroyed
      const script1 = document.querySelector(`script[src="${externalScript1}"]`)
      if (script1) {
        document.head.removeChild(script1)
      }
      const script2 = document.querySelector(`script[src="${externalScript2}"]`)
      if (script2) {
        document.head.removeChild(script2)
      }
    })

    return {
      firstName,
      lastName,
      signInPath,
      logOutPath
    }
  }
}
