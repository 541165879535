import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container min-vh-100 d-flex justify-content-center align-items-center" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("h2", null, "We've encountered an error!", -1)),
      _createElementVNode("p", null, _toDisplayString(_ctx.errorText), 1),
      (_ctx.redirectUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("p", null, "Please return to your homepage and attempt to re-enter the sign-in area.", -1)),
            _createElementVNode("button", {
              class: "btn btn-primary",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.redirectUser()), ["prevent"]))
            }, " Return to Homepage ")
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
            _createElementVNode("p", null, "Please use the back button below or in your browser to return to the previous page and try again.", -1),
            _createElementVNode("button", {
              class: "btn btn-primary",
              onclick: "history.back();return false;"
            }, " Back ", -1)
          ])))
    ])
  ]))
}