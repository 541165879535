import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "user-profile-container"
}
const _hoisted_2 = { class: "user-profile d-flex p-3 align-items-center border-bottom mb-4" }
const _hoisted_3 = {
  class: "user-profile-picture",
  "aria-hidden": "true"
}
const _hoisted_4 = { class: "user-profile-info flex-fill ms-3" }
const _hoisted_5 = { class: "user-profile-name mb-1" }
const _hoisted_6 = { class: "user-profile-actions" }
const _hoisted_7 = { class: "row g-4" }
const _hoisted_8 = { class: "col-md-12" }
const _hoisted_9 = { class: "profile-info-grid" }
const _hoisted_10 = { class: "profile-info-item" }
const _hoisted_11 = { class: "profile-info-value" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "profile-info-item" }
const _hoisted_14 = { class: "profile-info-value" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = {
  key: 1,
  class: "text-muted fst-italic"
}
const _hoisted_17 = { class: "profile-info-item" }
const _hoisted_18 = { class: "profile-info-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.oktaUser)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString($setup.createNameInitials($setup.firstName, $setup.lastName)), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("h3", _hoisted_5, _toDisplayString($setup.firstName) + " " + _toDisplayString($setup.lastName), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "profile-section-title" }, [
              _createElementVNode("i", { class: "bi bi-person-fill me-2" }),
              _createTextVNode(" Contact Information ")
            ], -1)),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "profile-info-label" }, "Email", -1)),
                _createElementVNode("div", _hoisted_11, [
                  _cache[0] || (_cache[0] = _createElementVNode("i", { class: "bi bi-envelope me-2 text-primary" }, null, -1)),
                  _createElementVNode("a", {
                    href: `mailto:${$setup.email}`
                  }, _toDisplayString($setup.email), 9, _hoisted_12)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "profile-info-label" }, "Phone", -1)),
                _createElementVNode("div", _hoisted_14, [
                  _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-telephone me-2 text-primary" }, null, -1)),
                  ($setup.phoneNumber.number)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, "+" + _toDisplayString($setup.phoneNumber.dialingCode) + " " + _toDisplayString($setup.phoneNumber.number), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_16, " Not provided "))
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _cache[5] || (_cache[5] = _createElementVNode("span", { class: "profile-info-label" }, "Country", -1)),
                _createElementVNode("div", _hoisted_18, [
                  _cache[4] || (_cache[4] = _createElementVNode("i", { class: "bi bi-pin-map me-2 text-primary" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString($setup.formatCountryName($setup.countryCode)), 1)
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}